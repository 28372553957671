import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";

import BlogList from "../sections/blog/BlogListSidebar";
import { graphql } from "gatsby";

const BlogRegular = ({ pageContext: { tag }, data }) => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5" />
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Text>Our posts on the topic of</Text>
                <Title variant="hero" pb={"2rem"}>
                  {tag}
                </Title>
              </Col>
            </Row>
          </Container>
        </Section>
        <div
          style={{ maxWidth: "900px", margin: "1rem auto", padding: "0 1rem" }}
        >
          <BlogList data={data.posts.nodes} />
        </div>
      </PageWrapper>
    </>
  );
};
export default BlogRegular;

export const query = graphql`
  query TagPage($tag: String) {
    posts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/blog//" }
        frontmatter: { tags: { in: [$tag] } }
      }
      sort: { fields: frontmatter___date, order: ASC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM Do YYYY")
          description
          featuredimage {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
